export default (c, instance, tag) => {
  return {
    uploadFiles(files, contract_guid, is_final) {
      return instance(c, tag).post(`/files/upload`, files, {
        params: {
          tag,
          contract_guid,
          is_final,
        },
      });
    },
    uploadVideo(files, contract_guid, is_final) {
      return instance(c, tag).post(`/files/upload/video`, files, {
        params: {
          tag,
          contract_guid,
          is_final,
        },
      });
    },
    // Для выгрузки всех файлов юзера из БД
    // getClientFiles(contract_guid) {
    //   return instance(c, tag).get(`/files/client/files`, {
    //     params: {
    //       contract_guid,
    //     },
    //   });
    // },
  };
};
