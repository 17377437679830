import * as Sentry from '@sentry/vue';
import { authStage } from '~/helpers/auth';
import formatPhoneNumberFrom1C from '~/helpers/formatPhoneNumberFrom1C';
import { useUserStore } from '~/stores/user';

const pages = {
  login: '/login',
  index: '/',
};

const settings = {
  login: {
    fields: {
      login: 'phone',
      password: 'password',
    },
  },
};

const authFieldsMap = {
  user: 'user',
};

export const useAuthStore = defineStore(
  'auth',
  () => {
    const { $api, $ws, $telemetryLog } = useNuxtApp();

    const router = useRouter();
    const userStore = useUserStore();
    const appStore = useAppStore();
    const chatStore = useChatStore();
    const dataForLongStore = useDataForLongStore();
    const processingStore = useProcessingStore();

    const stage = ref(authStage.WITH_PASSWORD);
    const phoneNumber = ref('');
    const refreshPromise = ref(null);

    const isLoggedIn = ref(false);

    const $reset = () => {
      isLoggedIn.value = false;
    };

    const login = async (payload) => {
      const { data } = await $api.web().login({
        [settings.login.fields.login]: payload[settings.login.fields.login],
        [settings.login.fields.password]: payload[settings.login.fields.password],
        token: payload.token,
      });

      userStore.user = data.data[authFieldsMap.user];
      const phoneNumber = data.data[authFieldsMap.user].phone;
      const formattedNumber = formatPhoneNumberFrom1C(phoneNumber);
      userStore.user.phone = payload[settings.login.fields.login] || formattedNumber;
      dataForLongStore.mobile_phone = payload[settings.login.fields.login] || formattedNumber;
      userStore.clientId = data.data.clientId;
      userStore.brandId = data.data.brandId;
      chatStore.wssToken = data.data.wssToken;
      isLoggedIn.value = true;

      if (payload.token) {
        await $telemetryLog({
          event_name: 'login_after_redirect',
        });
      }

      Sentry.captureMessage('[ok] autorization');
    };

    const logout = async (instance) => {
      try {
        if (instance) {
          await instance.get('/auth/logout');
        } else {
          await $api.web().logout();
        }
      } catch (e) {
      } finally {
        $ws.clear();
        $reset();

        localStorage.removeItem('user');
        localStorage.removeItem('dataForLong');
        localStorage.clear();

        userStore.$reset();
        dataForLongStore.$reset();
        appStore.$reset();
        chatStore.$reset();
        processingStore.$reset();

        router.push(pages.login);
      }
    };

    return {
      $reset,
      phoneNumber,
      stage,
      isLoggedIn,
      refreshPromise,

      login,
      logout,
    };
  },
  {
    persist: {
      paths: ['isLoggedIn', 'phoneNumber'],
    },
  },
);
