import { v4 as uuidv4, validate as uuidValidate } from 'uuid';

export default defineNuxtRouteMiddleware((to, from) => {
  const query = Object.assign({}, to.query);

  if (query.tm_session_id && uuidValidate(query.tm_session_id)) {
    const telemetrySessionId = useCookie('tm_session_id', {
      maxAge: 60 * 60 * 6,
      path: '/',
    });
    telemetrySessionId.value = query.tm_session_id;
  } else {
    const telemetrySessionId = useCookie('tm_session_id', {
      maxAge: 60 * 60 * 6,
      path: '/',
    });
    if (!telemetrySessionId.value) {
      telemetrySessionId.value = uuidv4();
    }
  }

  if (!((to.path === '/login' || to.path === '/') && to.path === from.path)) {
    return;
  }
  const pageqs = useCookie('pageqs');
  if (!pageqs.value) {
    if (query.order_id) {
      const dataForLongStore = useDataForLongStore();
      // старое поведение - редирект после 1 шага
      dataForLongStore.order_id = query.order_id;
      dataForLongStore.crm_click_id = query.crm_click_id;
    }
    if (Object.keys(query)?.length && query.utm_source === 'affiliate') {
      const pageqs = useCookie('pageqs', {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      });

      const isNewRefs =
        query?.from2 &&
        query?.click_id &&
        pageqs?.value?.from2 !== query.from2 &&
        pageqs?.value?.click_id !== query.click_id;

      if (!pageqs?.value || isNewRefs) {
        const partnerQueries = {
          from2: true,
          utm_medium: true,
          utm_source: true,
          wm_id: true,
          webmaster_id: true,
          utm_content: true,
          utm_campaign: true,
          click_id: true,
          clickid: true,
          transaction_id: true,
          utm_term: true,
          offer_id: true,
          offer: true,
          need_postback: true,
          goal_id: true,
          goalid: true,
          goal: true,
        };
        Object.keys(query).forEach((key) => {
          if (!partnerQueries[key]) delete query[key];
        });

        pageqs.value = query;
      } else {
        pageqs.value = query;
      }
    }
  }
});
