import { useDataForLongStore } from '~/stores/dataForLong';

export default defineNuxtRouteMiddleware(async () => {
  try {
    const { $api } = useNuxtApp();
    const dataForLongStore = useDataForLongStore();
    const pageqs = useCookie('pageqs')?.value || {};
    const { data } = await $api.web().saveClick({
      ...pageqs,
      referrer: useCookie('referrer')?.value ?? '',
    });
    dataForLongStore.crm_click_id = data?.data?.crm_click_id;
  } catch (err) {}
});
