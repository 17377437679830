<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import { onMounted } from 'vue';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App } from '@capacitor/app';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { useAppStore } from '~/stores/app';
import { useUserStore } from '~/stores/user';

const appStore = useAppStore();
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();
const nuxtApp = useNuxtApp();

// const {
//   public: { nodeEnv },
// } = useRuntimeConfig();

nuxtApp.hook('page:finish', () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

App.addListener('backButton', ({ canGoBack }) => {
  if (appStore.menuMobileOpen) {
    appStore.setMenuMobileOpen(false);
    return;
  }
  if (canGoBack) {
    router.back();
  } else {
    App.exitApp();
  }
});

// const scriptTagInner = `(function(m, e, t, r, i, k, a) {
//     m[i] = m[i]

//     || function() {
//         (m[i].a = m[i].a || []).push(arguments)
//     };
//     m[i].l = 1 * new Date();
//     for (var j = 0; j < document.scripts.length; j++) {
//         if (document.scripts[j].src === r) {
//             return;
//         }
//     }
//     k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
// })
// (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

// ym(30533147, "init", {
//     clickmap: true,
//     trackLinks: true,
//     accurateTrackBounce: true,
//     webvisor: true
// });`;
// const nosctipTagInner = `<div><img src="https://mc.yandex.ru/watch/30533147" style="position:absolute; left:-9999px;" alt="" /></div>`;

useHead({
  title: '«Деньги на дом» — Личный кабинет',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content:
        'Личный кабинет компании Деньги на дом. Для входа понадобится телефон и пароль. Для входа нажмите «Войти»',
    },
    // {
    //   name: 'viewport',
    //   content: 'width=device-width, initial-scale=1, maximum-scale=1',
    // },
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'apple-touch-icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'apple-touch-icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
  ],

  // script: nodeEnv === 'production' ? [{ type: 'text/javascript', children: scriptTagInner }] : [],
  // noscript: nodeEnv === 'production' ? [{ children: nosctipTagInner }] : [],
});

userStore.isInitialGetUserStatesLoading = true;

// const intervalId = ref(null);
// const getUserStates = async () => {
//   await userStore.getUserStates();
// };

// const startInterval = () => {
//   clearInterval(intervalId.value); // Очистка предыдущего интервала, если он существует
//   if ([8, 14, 15, 18, 19].includes(userStore.user.status_code)) {
//     const delayTime = ref(60000);
//     // Для 15, 18 и 19 статус-кодов установить время интервала 15 сек
//     if ([15, 18, 19].includes(userStore.user.status_code)) {
//       delayTime.value = 15000;
//     }

//     intervalId.value = setInterval(getUserStates, delayTime.value); // Запуск нового интервала
//   }
// };

// watch(
//   () => route.path,
//   () => {
//     startInterval(); // Перезапуск интервала при каждом изменении маршрута
//   },
// );

onBeforeMount(async () => {
  // await SplashScreen.show({
  //   showDuration: 3000,
  //   autoHide: true,
  // });
});

onMounted(async () => {
  if (usePlatform().value !== 'web') {
    await StatusBar.setStyle({ style: Style.Light });
    ScreenOrientation.lock({ orientation: 'portrait' });
  }

  if (
    ![
      'login',
      'r-slug',
      'getPassword',
      'emailVerification',
      'newPhoneVerification',
      'long',
      'loan',
    ].includes(route.name)
  ) {
    await userStore.getUserStates();
    userStore.isInitialGetUserStatesLoading = false;
  }
  if (route.query.token) {
    appStore.qsToken = route.query.token;
  }
  // startInterval();
});

// onUnmounted(() => {
//   clearInterval(intervalId.value);
// });
</script>
