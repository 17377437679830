import axios from 'axios';
import { useAuthStore } from '~/stores/auth';
import { useAppStore } from '~/stores/app';
import { useFilesUploadStore } from '~/stores/uploadFiles';
import { useChatStore } from '~/stores/chat';
const webEndpoints = {
  login: '/auth/login',
  logout: '/auth/logout',
  refresh: '/auth/refresh',
};

export default class Instances {
  static web(c) {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const chatStore = useChatStore();

    const instance = axios.create({
      baseURL: c.public.baseURL,
      withCredentials: true,
    });

    const createAxiosResponseInterceptor = () => {
      const interceptor = instance.interceptors.response.use(
        (response) => {
          appStore.fail1c = response?.data?.fail1c || false;
          return response;
        },
        async (error) => {
          if (
            error.response?.status !== 401 ||
            (error.config.url.includes('getUserStates') && error.response?.status !== 401)
          ) {
            throw error;
          }

          instance.interceptors.response.eject(interceptor);

          try {
            if (error.config.url === webEndpoints.refresh) throw error;

            if (!authStore.refreshPromise) {
              authStore.refreshPromise = new Promise((resolve, reject) => {
                instance
                  .get(webEndpoints.refresh)
                  .then((refreshData) => {
                    if (refreshData?.data?.wssToken) {
                      chatStore.wssToken = refreshData.data.data.wssToken;
                    }
                  })
                  .then(resolve)
                  .catch(reject)
                  .finally(() => {
                    authStore.refreshPromise = null;
                  });
              });
            }
            await authStore.refreshPromise;

            const res = await axios(error.response.config);

            return res;
          } catch {
            await authStore.logout(instance);
          }
        },
      );
    };

    createAxiosResponseInterceptor();
    return instance;
  }

  static disk(c, tag) {
    const filesUploadStore = useFilesUploadStore();
    const instance = axios.create({
      baseURL: c.public.baseURL,
      withCredentials: true,

      headers: {
        'Content-Type': 'multipart/formdata',
      },

      onUploadProgress: function (progressEvent) {
        filesUploadStore.filesObj[tag].progress = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100),
        );
      },
    });

    const createAxiosResponseInterceptor = () => {
      const interceptor = instance.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          if (error.response?.status && error.response.status !== 401) {
            throw error;
          }

          instance.interceptors.response.eject(interceptor);

          await this.web(c).get(webEndpoints.refresh);

          const res = await axios(error.response.config);

          return res;
        },
      );
    };
    createAxiosResponseInterceptor();

    return instance;
  }

  static chat(c) {
    const instance = axios.create({
      baseURL: c.public.chatServerUrl,
      withCredentials: true,

      headers: {
        Authorization: 'Bearer ' + c.public.chatServerToken,
      },
    });

    return instance;
  }

  static diskChat(c) {
    const instance = axios.create({
      baseURL: c.public.chatServerUrl,
      withCredentials: true,

      headers: {
        'Content-Type': 'multipart/formdata',
        Authorization: 'Bearer ' + c.public.chatServerToken,
      },
    });

    return instance;
  }

  static geo(c) {
    return axios.create({
      baseURL: c.public.dadataURL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + c.public.dadataToken,
      },
    });
  }

  static payment(c) {
    return axios.create({
      baseURL: c.public.paymentServerUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  static siteBack(c) {
    return axios.create({
      baseURL: c.public.siteBackUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}
