export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    mounted(el, binding) {
      el.clickOutsideEvent = (event) => {
        if (binding.value.exclude().contains(event.target)) {
          return;
        }

        if (!(el === event.target || el.contains(event.target))) {
          if (binding.value) {
            binding.value.handler();
          }
        }
      };
      document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.removeEventListener('click', el.clickOutsideEvent);
    },
  });
});
