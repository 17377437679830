import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_1UohGbtF8v from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import maska_6b8AiMALBy from "/app/node_modules/@dnd/ui-layer/plugins/maska.js";
import index_VHbHwe8vN1 from "/app/plugins/api/index.js";
import clickOutside_client_kJnKuQOEv2 from "/app/plugins/clickOutside.client.js";
import dayjs_FoMn5Zigri from "/app/plugins/dayjs.js";
import errorHandler_cfkZ75xwSQ from "/app/plugins/errorHandler.js";
import getChatFileUrl_4E4LbjgAa9 from "/app/plugins/getChatFileUrl.js";
import login_jRy0QkkNqX from "/app/plugins/login.js";
import sentry_client_GoGQuZo4Et from "/app/plugins/sentry.client.js";
import socket_client_N9cHubToyQ from "/app/plugins/socket.client.js";
import telemetryLog_mtpbUpoP91 from "/app/plugins/telemetryLog.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_1UohGbtF8v,
  maska_6b8AiMALBy,
  index_VHbHwe8vN1,
  clickOutside_client_kJnKuQOEv2,
  dayjs_FoMn5Zigri,
  errorHandler_cfkZ75xwSQ,
  getChatFileUrl_4E4LbjgAa9,
  login_jRy0QkkNqX,
  sentry_client_GoGQuZo4Et,
  socket_client_N9cHubToyQ,
  telemetryLog_mtpbUpoP91
]