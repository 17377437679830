export default (o, instance) => {
  return {
    getPaymentUrl(data) {
      return instance(o).post('/payment/contract/pay', data);
    },

    getPaymentStatus(id) {
      return instance(o).get('/payment', { params: { id } });
    },

    getPaymentContract(data) {
      return instance(o).get('/payment/contract', { params: data });
    },
  };
};
