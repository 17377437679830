export default defineNuxtRouteMiddleware((to) => {
  const authCookie = useCookie('auth')?.value;
  if (authCookie?.isLoggedIn) {
    switch (to.name) {
      case 'index':
      case 'login':
        return navigateTo('/loan');
      default:
        return;
    }
  }

  switch (to.name) {
    case 'login':
    case 'r-slug':
    case 'emailVerification':
    case 'newPhoneVerification':
      return;
    default:
      return navigateTo('/login');
  }
});
