export default (o, instance) => {
  return {
    generateNewPassword(data) {
      return instance(o).post('/password/new', {
        phone: data.phone,
      });
    },

    login(data) {
      const body = {};
      if (data.phone && data.password) {
        body.phone = data.phone;
        body.password = data.password;
      }
      if (data.token) {
        body.token = data.token;
      }
      return instance(o).post('/auth/login', body);
    },
    register(data) {
      return instance(o).post('/auth/register', {
        phone: data.phone,
      });
    },
    logout() {
      return instance(o).get('/auth/logout');
    },
    refresh() {
      return instance(o).get('/auth/refresh');
    },
    getDraftData(order_id) {
      return instance(o).get('/infoUser/getDraftData', {
        params: {
          order_id,
        },
      });
    },
    getLastRequestData() {
      return instance(o).get('/infoUser/getLastRequestData');
    },
    saveDraftData(payload) {
      return instance(o).post(`/infoUser/saveDraftData`, payload);
    },
    updateUserInfo(data) {
      return instance(o).post('/infoUser/updateUserInfo', data);
    },
    mailMetrikaDisabled(data) {
      return instance(o).post('/infoUser/mailMetrika', data);
    },
    tempRegistrationRequired(kladr_id) {
      return instance(o).get('/info/tempRegistrationRequired', {
        params: {
          kladr_id,
        },
      });
    },
    getUserStates() {
      return instance(o).get('/infoUser/getUserStates');
    },
    getLoanInfo() {
      return instance(o).get('/infoUser/getLoanInfo');
    },
    setPermanentPassword(pass) {
      return instance(o).post('/user/setPermanentPassword', {
        password: pass,
      });
    },
    getCurrentDateTime() {
      return instance(o).get('/info/currentDateTime');
    },
    addLoanApplication(payload) {
      return instance(o).post('/backCall', payload);
    },
    backCallFilesScenario() {
      return instance(o).post('/backCall/filesScenario');
    },
    getDateOfCompletion() {
      return instance(o).get('/infoUser/dateOfCompletion');
    },
    getOrderId(mobile) {
      return instance(o).post('/infoUser/orderId', { mobile });
    },
    getPartnerLink(phone) {
      return instance(o).post('/infoUser/partnerLink', { phone });
    },
    getECPCode({ order_id, code_name }) {
      return instance(o).get('/infoUser/getECPCode', {
        params: {
          order_id,
          code_name,
        },
      });
    },
    saveClick(params) {
      return instance(o).post('/long/click', {}, { params });
    },
    sendVerifyEmail(data) {
      return instance(o).post('/email/sendVerify', data);
    },
    verifyEmailByToken(token) {
      return instance(o).post('/email/verifyByToken', { token });
    },
    sendChangeNumberConfirmationToEmail(data) {
      return instance(o).post('/email/sendChangeNumber', data);
    },
    sendSmsCodeByToken(token) {
      return instance(o).post('/sms/sendCodeByToken', { token });
    },
    verifySmsCodeByToken(data) {
      return instance(o).post('/sms/verifyCodeByToken', data);
    },
    changeNumberByToken(token) {
      return instance(o).post('/infoUser/changeNumberByToken', { token });
    },
    sendSmsCode(phone) {
      return instance(o).post('/sms/sendCode', { phone });
    },
    verifySmsCode(data) {
      return instance(o).post('/sms/verifyCode', data);
    },
    changeNumber(newPhone) {
      return instance(o).post('/infoUser/changeNumber', { newPhone });
    },
    checkPhoneExisting(phone) {
      return instance(o).post('/infoUser/checkPhoneExisting', { phone });
    },
    getUserPayments() {
      return instance(o).get('/infoUser/getUserPayments');
    },
    getProductMatrix() {
      return instance(o).get('/info/productMatrix');
    },
    completeUserSale(partner) {
      return instance(o).post('/infoUser/completeUserSale', {
        partner,
      });
    },
    getBanksList() {
      return instance(o).get('/info/banks');
    },
    checkCardIsValid(card_number) {
      return instance(o).get('/info/card/check', {
        params: { card_number },
      });
    },
    getValidCards() {
      return instance(o).get('/infoUser/cards');
    },
    sendPaymentChoiseInfo(data) {
      return instance(o).post('/infoUser/paymentChoiseInfo', data);
    },
    getVerificationFiles(contract_guid) {
      return instance(o).get('/infoUser/verificationFiles', {
        params: { contract_guid },
      });
    },
    getClientMatrix() {
      return instance(o).get('/infoUser/matrix');
    },
    sendProductChoise(data) {
      return instance(o).post('/infoUser/productChoiseInfo', data);
    },
    getClientDocs(status_code) {
      return instance(o).get('/infoUser/getClientDocs', {
        params: { status_code },
      });
    },
    sendDocsSmsCode(status_code) {
      return instance(o).post('/sms/docsSign/sendCode', { status_code });
    },
    verifyDocsSmsCode(data) {
      return instance(o).post('/sms/docsSign/verifyCode', data);
    },
    getLinkToAddCard() {
      return instance(o).get('/infoUser/getLinkToAddCard');
    },
    getClientsFiles() {
      return instance(o).get('/files/clients');
    },
    sendTelemetryEvent(data) {
      return instance(o).post('/info/telemetry', data);
    },
    loanCorrectionInfo(data) {
      return instance(o).post('/loanCorrection/info', { corrections: data });
    },
    loanCorrectionDocs(data) {
      return instance(o).post('/loanCorrection/docs', data);
    },
    loanCorrectionsGetVerificationCode(correction_type) {
      return instance(o).post('/loanCorrection/sendVerificationCode', { correction_type });
    },
    loanCorrectionsSendVerificationCode(correction_type, code) {
      return instance(o).put('/loanCorrection/verifyCode', { correction_type, code });
    },
    getTokenByShortId(short_id) {
      return instance(o).get('/shortLink/getToken', {
        params: {
          short_id,
        },
      });
    },
  };
};
