import { defineNuxtPlugin } from '#imports';
import { useChatFilesStore } from '~/stores/chatFiles';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const chatFilesStore = useChatFilesStore();

  nuxtApp.provide('getChatFileUrl', (file) => {
    const params = new URLSearchParams({
      sign: chatFilesStore.filesSign,
    });
    return `${config.public.chatServerUrl}/files/${file}?${params}`;
  });
});
