import pick from 'lodash.pick';
import set from 'lodash.set';
// import { step1Fields, step2Fields, step3Fields, step4Fields } from '~/helpers/dataForLongSteps';

const adjustmentsDefault = {
  last_name: 0,
  first_name: 0,
  secondary_name: 0,
  passport_number: 0,
  birthday: 0,
};

const step1Default = {
  city: '', // Город
  first_name: '', // Имя
  last_name: '', // Фамилия
  secondary_name: '', // Отчество
  email: '', // Электронная почта
  addr_city_full: {}, // Город
  cityKLADR_residence_kladr_id: '', // КЛАДР ID места жительства
  cityKLADR_residence_region_with_type: '', // Регион места жительства с типом
  cityKLADR_residence_area_with_type: '', // Район места жительства с типом
  cityKLADR_residence_city_with_type: '', // Город места жительства с типом
  cityKLADR_residence_settlement_with_type: '', // Населенный пункт места жительства с типом
  cityKLADR_residence_street_with_type: '', // Улица места жительства с типом
  cityKLADR_residence_house: '', // Дом места жительства
  cityKLADR_residence_house_type: '', // Тип дома места жительства
  cityKLADR_residence_block: '', // Корпус места жительства
  cityKLADR_residence_block_type: '', // Тип корпуса места жительства
  cityKLADR_residence_flat: '', // Квартира места жительства
  cityKLADR_residence_flat_type: '', // Тип квартиры места жительства
  cityKLADR_residence_postal_code: '', // Почтовый индекс места жительства
  cityKLADR_residence_geo_lat: '', // Географическая широта места жительства
  cityKLADR_residence_geo_lon: '', // Географическая долгота места жительства
};

const step2Default = {
  passport_serie: '', // Серия паспорта
  passport_number: '', // Номер паспорта
  passport_date: '', // Дата выдачи
  passport_city: '', // Место выдачи
  passport_devcode: '', // Код подразделения
  passport_issuedby: '', // Кем выдан
  birthday: '', // Дата рождения
  birthplace: '', // Место рождения
};

const step3Default = {
  addr_residence_full: {},
  addr_registration_full: {},
  lack_of_registration: false, // Нет адреса регистрации
  equal_registration: false, // Совпадает с адресом фактического проживания
  equal_temp_registration: false,
  addr_temp_registration_full: {},
  address_difference: false,

  // Адрес фактического проживания
  adressKLADR_residence_kladr_id: '', // ID записи по КЛАДР
  adressKLADR_residence_region_with_type: '', // Регион с типом
  adressKLADR_residence_area_with_type: '', // Район с типом
  adressKLADR_residence_city_with_type: '', // Город с типом
  adressKLADR_residence_settlement_with_type: '', // Населенный пункт с типом
  adressKLADR_residence_street_with_type: '', // Улица с типом
  adressKLADR_residence_house: '', // Номер дома
  adressKLADR_residence_house_type: '', // (50)	Тип дома
  adressKLADR_residence_block: '', // Корпус
  adressKLADR_residence_block_type: '', // Тип корпуса
  adressKLADR_residence_flat: '', // Квартира
  adressKLADR_residence_flat_type: '', // Тип квартиры
  adressKLADR_residence_postal_code: '', // Почтовый индекс
  adressKLADR_residence_geo_lat: '', // Координаты. Широта
  adressKLADR_residence_geo_lon: '', // Координаты. Долгота

  // Алрес постоянной регистрации
  adressKLADR_registration_kladr_id: '', // ID записи по КЛАДР
  adressKLADR_registration_region_with_type: '', // Регион с типом
  adressKLADR_registration_area_with_type: '', // Район с типом
  adressKLADR_registration_city_with_type: '', // Город с типом
  adressKLADR_registration_settlement_with_type: '', // Населенный пункт с типом
  adressKLADR_registration_street_with_type: '', // Улица с типом
  adressKLADR_registration_house: '', // Номер дома
  adressKLADR_registration_house_type: '', // (50)	Тип дома
  adressKLADR_registration_block: '', // Корпус
  adressKLADR_registration_block_type: '', // Тип корпуса
  adressKLADR_registration_flat: '', // Квартира
  adressKLADR_registration_flat_type: '', // Тип квартиры
  adressKLADR_registration_postal_code: '', // Почтовый индекс
  adressKLADR_registration_geo_lat: '', // Координаты. Широта
  adressKLADR_registration_geo_lon: '', // Координаты. Долгота

  // Адрес временной регистрации
  adressKLADR_temp_registration_kladr_id: '', // ID записи по КЛАДР
  adressKLADR_temp_registration_region_with_type: '', // Регион с типом
  adressKLADR_temp_registration_area_with_type: '', // Район с типом
  adressKLADR_temp_registration_city_with_type: '', // Город с типом
  adressKLADR_temp_registration_settlement_with_type: '', // Населенный пункт с типом
  adressKLADR_temp_registration_street_with_type: '', // Улица с типом
  adressKLADR_temp_registration_house: '', // Номер дома
  adressKLADR_temp_registration_house_type: '', // (50)	Тип дома
  adressKLADR_temp_registration_block: '', // Корпус
  adressKLADR_temp_registration_block_type: '', // Тип корпуса
  adressKLADR_temp_registration_flat: '', // Квартира
  adressKLADR_temp_registration_flat_type: '', // Тип квартиры
  adressKLADR_temp_registration_postal_code: '', // Почтовый индекс
  adressKLADR_temp_registration_geo_lat: '', // Координаты. Широта
  adressKLADR_temp_registration_geo_lon: '', // Координаты. Долгота
};

const step4Default = {
  // Информация о работе

  work_mode: -1, // Статус занятости: -1 - не указано; 0 - работает; 1 - не работает; 2 - пенсионер
  fixed_income: '', // Постоянный доход
  official_part_of_income: '', // Официальная часть дохода
  additional_income: '', // Дополнительный доход
  additional_income_comment: '', // Источник дополнительного дохода
  work_position: '', // Должность
  marital_status: -1, // Статус семейного положения
  spouse_full_name: '', // ФИО

  // Место работы

  work_phone: '', // Рабочий телефон клиента
  work_company_phone: '', // Телефон организации
  work_company: '', // Организация
  work_type_guid: '', // GUID записи сферы деятельности
  work_type_text: '', // Представление записи сферы деятельности
  work_type_comment: '', // Комментарий к деятельности
  work_experience_months: '', // Стаж, месяцев
  work_leader_full_name: '', // ФИО руководителя организации
  work_leader_phone: '', // Телефон организации

  // Адрес компании
  addr_work_full: {},
  adressKLADR_work_kladr_id: '', // ID записи по КЛАДР
  adressKLADR_work_region_with_type: '', // Регион с типом
  adressKLADR_work_area_with_type: '', // Район с типом
  adressKLADR_work_city_with_type: '', // Город с типом
  adressKLADR_work_settlement_with_type: '', // Населенный пункт с типом
  adressKLADR_work_street_with_type: '', // Улица с типом
  adressKLADR_work_house: '', // Номер дома
  adressKLADR_work_house_type: '', // (50)	Тип дома
  adressKLADR_work_block: '', // Корпус
  adressKLADR_work_block_type: '', // Тип корпуса
  adressKLADR_work_flat: '', // Квартира
  adressKLADR_work_flat_type: '', // Тип квартиры
  adressKLADR_work_postal_code: '', // Почтовый индекс
  adressKLADR_work_geo_lat: '', // Координаты. Широта
  adressKLADR_work_geo_lon: '', // Координаты. Долгота

  // Контактные лица

  contact_persons_1_kind_guid: '', // GUID записи вида контактного лица
  contact_persons_1_kind_text: '', // Представление записи вида контактного лица
  contact_persons_1_birthday: '', // Дата рождения контактного лица
  contact_persons_1_home_phone: '', // Домашний телефон контактного лица
  contact_persons_1_mobile_phone: '', // Мобильный телефон контактного лица
  contact_persons_1_full_name: '', // ФИО контактного лица
  contact_persons_2_kind_guid: '', // GUID записи вида контактного лица
  contact_persons_2_kind_text: '', // Представление записи вида контактного лица
  contact_persons_2_birthday: '', // Дата рождения контактного лица
  contact_persons_2_home_phone: '', // Домашний телефон контактного лица
  contact_persons_2_mobile_phone: '', // Мобильный телефон контактного лица
  contact_persons_2_full_name: '', // ФИО контактного лица
  contact_persons_3_kind_guid: '', // GUID записи вида контактного лица
  contact_persons_3_kind_text: '', // Представление записи вида контактного лица
  contact_persons_3_birthday: '', // Дата рождения контактного лица
  contact_persons_3_home_phone: '', // Домашний телефон контактного лица
  contact_persons_3_mobile_phone: '', // Мобильный телефон контактного лица
  contact_persons_3_full_name: '', // ФИО контактного лица

  // Прочее
  avg_monthly_payment: '', // Величина среднемесячных платежей по кредитам/займам, в том числе по которым выступает поручителем, созаемщиком
};

const loanDefault = {
  product_group_guid: '', // GUID записи категории продукта
  product_group_text: '', // Представление записи категории продукта
  product_summ: 0, // Сумма
  product_term: 0, // Срок
  product_freq: '', // Тип срока (d/w/m)
  calc_term_value: '',
  product_interest_rate: '', // Процентная ставка
  product_payment: '', // Платеж
  product_fcl: '', // Полная стоимость займа
  product_overpayment: '', // Переплата
  loan_obtaining_method_guid: '', // GUID записи способа выплаты const займа
  loan_obtaining_method_text: '', // Представление записи способа const выплаты займа
};

const fetchStepsDefault = {
  step_1: {},
  step_2: {},
  step_3: {},
  step_4: {},
  loan: {},
};

export const useDataForLongStore = defineStore(
  'dataForLong',
  () => {
    const { $api } = useNuxtApp();
    const userStore = useUserStore();
    const dataForLongStore = useDataForLongStore();

    const mobile_phone = ref('');
    const step = ref(1);
    const order_id = ref('');
    const crm_click_id = ref('');
    const payment_schedule = ref(['1']);
    const is_calc_loading = ref(true);
    const insertedFields = ref([]);
    const isDraftFetched = ref(false);

    const fetchSteps = reactive(fetchStepsDefault);
    const adjustments = reactive({ ...adjustmentsDefault });
    const loan = reactive({ ...loanDefault });

    // Step 1
    const step_1 = reactive({ ...step1Default });

    // Step 2
    const step_2 = reactive({ ...step2Default });

    // Step 3
    const step_3 = reactive({ ...step3Default });

    // Step 4
    const step_4 = reactive({ ...step4Default });

    const addrObj = {
      adressKLADR_registration_city_with_type: 'adressKLADR_registration_region_with_type',
      adressKLADR_residence_city_with_type: 'adressKLADR_residence_region_with_type',
      adressKLADR_temp_registration_city_with_type:
        'adressKLADR_temp_registration_region_with_type',
      adressKLADR_work_city_with_type: 'adressKLADR_work_region_with_type',
    };

    const setFieldsStep = (step) => {
      if (step === 'loan') {
        for (const key in fetchSteps[step]) {
          set(dataForLongStore[step], key, fetchSteps[step][key]);
        }
      } else {
        for (const key in fetchSteps[`step_${step}`]) {
          if (key in addrObj && fetchSteps[`step_${step}`][key] === '') {
            set(dataForLongStore[`step_${step}`], key, fetchSteps[`step_${step}`][addrObj[key]]);
          } else {
            set(dataForLongStore[`step_${step}`], key, fetchSteps[`step_${step}`][key]);
          }
        }
      }
    };

    const pickRequestData = (req) => {
      fetchSteps.step_1 = pick(req.data.data.data, Object.keys(step1Default));
      fetchSteps.step_2 = pick(req.data.data.data, Object.keys(step2Default));
      fetchSteps.step_3 = pick(req.data.data.data, Object.keys(step3Default));
      fetchSteps.step_4 = pick(req.data.data.data, Object.keys(step4Default));
      fetchSteps.loan = pick(req.data.data.data, Object.keys(loanDefault));
    };

    const getLastRequestData = async () => {
      const req = await $api.web().getLastRequestData();

      if (req.data.data.status === 0) {
        pickRequestData(req);

        setFieldsStep(1);
        setFieldsStep(2);
        setFieldsStep(3);
        setFieldsStep(4);

        isDraftFetched.value = true;
      }
    };

    const getDraftData = async (setAll) => {
      if (!isDraftFetched.value && userStore.user.draft_presence) {
        const req = await $api.web().getDraftData();

        if (req.data.data.status === 0) {
          pickRequestData(req);

          if (setAll) {
            setFieldsStep(1);
            setFieldsStep(2);
            setFieldsStep(3);
            setFieldsStep(4);
            setFieldsStep('loan');
          }

          isDraftFetched.value = true;

          if (req.data.data.data?.is_complete) {
            step.value = 1;
          }

          if (req.data.data.data?.first_name && req.data.data.data?.secondary_name) {
            step.value = 2;
          }

          if (req.data.data.data?.passport_serie) {
            step.value = 3;
          }

          if (req.data.data.data?.adressKLADR_residence_house) {
            step.value = 4;
          }
        }
      }
    };

    const getAdjustments = (returnFields) => {
      const counts = {
        last_name: 4,
        first_name: 4,
        secondary_name: 4,
        passport_number: 5,
        birthday: 5,
      };
      return (returnFields || Object.keys(counts)).reduce((acc, field) => {
        if (field in counts && adjustments[field] >= counts[field]) {
          acc.push(field);
        }
        return acc;
      }, []);
    };
    const getInsertions = (returnFields) => {
      const res = [];
      returnFields.forEach((field) => {
        const f = insertedFields.value.find((f) => f === field);
        if (f) res.push(f);
      });
      return res;
    };

    const $reset = () => {
      step.value = 1;
      payment_schedule.value = ['1'];
      is_calc_loading.value = true;
      order_id.value = '';
      crm_click_id.value = '';
      insertedFields.value = [];
      isDraftFetched.value = false;

      Object.assign(fetchSteps, fetchStepsDefault);
      Object.assign(adjustments, adjustmentsDefault);
      Object.assign(loan, loanDefault);
      Object.assign(step_1, step1Default);
      Object.assign(step_2, step2Default);
      Object.assign(step_3, step3Default);
      Object.assign(step_4, step4Default);
    };

    return {
      $reset,
      mobile_phone,
      step,
      step_1,
      step_2,
      step_3,
      step_4,
      loan,
      order_id,
      crm_click_id,
      insertedFields,
      adjustments,
      is_calc_loading,
      isDraftFetched,
      fetchSteps,
      getAdjustments,
      getInsertions,
      setFieldsStep,
      getDraftData,
      getLastRequestData,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);
