import { AxiosError } from 'axios';
import { useNotieStore } from '~/stores/notifications';

const getMessageByStatus = (status) => {
  if (status >= 400 && status < 500) {
    return 'Ошибка клиента, проверьте данные или попробуйте позже';
  } else if (status >= 500) {
    return 'Ошибка сервера, проверьте данные или попробуйте позже';
  } else {
    return 'Произошла неизвестная ошибка, попробуйте позже';
  }
};

const configureError = (error) => {
  if (error instanceof AxiosError) {
    const notieStore = useNotieStore();
    const message = error.response
      ? getMessageByStatus(error.response.status)
      : 'Ошибка сети или сервера';

    notieStore.addNotification({
      type: 'error',
      message,
    });
  }
};

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();

  nuxtApp.vueApp.config.errorHandler = (error, ctx) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line
      console.log(
        '%cError 🤡🤡🤡\n',
        'color: white; font-size: 16px;',
        error,
        '\n',
        '\n',
        '⬆️⬆️⬆️ ERROR CONTEXT ⬆️⬆️⬆️\n',
        ctx,
      );
    }

    configureError(error);

    // const user = useCookie('user')?.value?.user;
    // let username;
    // if (user && Object.keys(user).length > 0) {
    //   username = [user.phone, user.last_name, user.first_name, user.secondary_name]
    //     .filter((i) => i !== undefined && i !== '')
    //     .join(' ');
    // }
  };
});
